<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('meeting_report')"
                        :isNewButton="false"
                        :isColumns="false"
                        :actions="subMenu"
                        @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('meeting_report')"
                              :isNewButton="false"
                              :isColumns="false"
                              @filter-div-status="datatable.filterStatus=$event"
                >
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column,key) in datatable.columns" :key="key">
                            <b-form-checkbox
                                :id="'checkbox-'+key"
                                :name="'checkbox-'+key"
                                :value="false"
                                :unchecked-value="true"
                                v-model="column.hidden"
                                v-if="column.field!='buttons'"
                            >
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter
                v-show="datatable.filterStatus"
                :filterButton="false"
                @filterClear="filterClear"
                :exportExcel="true"
                @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('date_between')">
                            <date-between
                                v-model="datatable.queryParams.filter.date_between"></date-between>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('program')">
                            <program-selectbox
                                v-model="datatable.queryParams.filter.program_code"
                                :is_faculty_code_required="true"
                                :faculty_code="datatable.queryParams.filter.faculty_code"></program-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('program_level')">
                            <program-level-selectbox
                                v-model="datatable.queryParams.filter.program_level"></program-level-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('accepttance_status')">
                            <parameter-selectbox
                                code="graduate_application_status"
                                v-model="datatable.queryParams.filter.acceptance_status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4">
                        <b-form-group :label="$t('application_completion_status')">
                            <parameter-selectbox
                                code="graduate_application_completion_status"
                                v-model="datatable.queryParams.filter.completion_status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>

            </datatable-filter>
        </app-layout>
    </div>
</template>
<script>
// Template
import AppLayout from "@/layouts/AppLayout"
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"

// Components
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import DateBetween from "@/components/interactive-fields/DateBetween";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox"
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox"
import ProgramLevelSelectbox from "@/components/interactive-fields/ProgramLevelSelectbox"
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox"

//Pages

// Services
import GraduateApplicationService from "@/services/GraduateApplicationService";

// Others
import {ValidationObserver, ValidationProvider} from "vee-validate"
import qs from 'qs'
import moment from "moment";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        DatatableFilter,
        Datatable,
        DateBetween,
        SemestersSelectbox,
        FacultySelectbox,
        ProgramSelectbox,
        ProgramLevelSelectbox,
        ParameterSelectbox,

        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t('graduate_applications')+' | '+this.$t('meeting_report')
        }
    },
    data() {
        return {
            datatable: {
                isLoading: false,
                columns: [

                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: 'id',
                    page: 1,
                    limit: -1
                }
            },

            subMenu: [
                {
                    text: this.$t('applications'),
                    class: 'ri-file-text-line',
                    permission: 'graduateapplication_index',
                    callback: () => {
                        this.$router.push('/graduate/applications');
                    }
                },
                {
                    text: this.$t('meetings'),
                    class: 'ri-calendar-todo-line',
                    permission: 'graduateapplicationmeeting_index',
                    callback: () => {
                        this.$router.push('/graduate/application/meetings');
                    }
                },
                {
                    text: this.$t('meeting_report'),
                    class: 'ri-folder-chart-line',
                    permission: 'graduateapplicationmeeting_index',
                    callback: () => {
                        return false;
                    }
                },
            ],
            isAdmin: false,
            currentUserId: null,

        }
    },
    created() {
        this.$store.dispatch("auth/initUser");
        let user = this.$store.getters['auth/getUser']
        this.isAdmin = this.$store.getters['auth/isAdmin']
        this.currentUserId = user.id
        this.filterSet();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {

            }
        },
        filterClear() {
            this.filterSet()
        },

        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            GraduateApplicationService.excelExportMeeting(config)
                                 .then(res => this._downloadFile(res, this.$t("meetings") + '.xlsx'))
                                 .catch(err => this.showErrors(err))
        }

    }
}
</script>

